import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
// import Seo from "../components/Seo"
import { connect } from 'react-redux';
import Helper from '@justnice/helper';
import qs from 'qs';
import Markdown from 'markdown-to-jsx';

import * as systemActions from '../redux/actions/systemActions';
import backgroundImage from '../images/content_bg.png';

const ContentPage : React.FC = (props) => {
  const locale = props.ui.get('lang');
  const contentList = Helper.getStateAsJSObj(props['system'], 'content.data', []);
  
  let queryParam = null;
  let selectedContent = null;

  if(Helper.stringHasValue(props.location?.search))
    queryParam = qs.parse(props.location.search.substr(1));

  if(queryParam) {
    selectedContent = contentList.find(item => item.category === 'WEBCONTENT' && item.type === queryParam.type);
  }

  if(!Helper.isNotNullAndUndefined(selectedContent)) {
    selectedContent = {
      title: '',
      content: ''
    };
  }

  React.useEffect(() => {
    const contentObj = Helper.getStateAsJSObj(props['system'], 'content', null);

    // If no content info and content list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(contentObj) || 
       (!Helper.arrayHasItem(contentObj?.data) && !contentObj?.loading) ||
       (contentObj?.locale !== locale && !contentObj?.loading)) {
      props.dispatch(
        systemActions.contentListGet(locale)
      );
    }

  }, []);

  return(
    <Layout title={selectedContent.title} authRequired={false}
      backgroundImage={backgroundImage}
      backgroundStyle={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
      }}
    >
      {/* <Seo title={selectedContent.title} /> */}
      <Markdown style={{marginTop: 10, whiteSpace: 'pre-wrap'}}>
        {selectedContent.content}
      </Markdown>
      
    </Layout>
  )
}

export default connect((state) => {
  return {
    ui: state['ui'],
    page: state['page'],
    system: state['system']
  };
})(ContentPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;